<template>
    <div>
        <a-upload
                accept=".jpeg,.jpg,.png,.gif,.bmp,.tiff,.tif,.webp,.svg,.ico,.raw"
                :disabled="isShow"
                :action="uploadUrl"
                :maxCount="count"
                list-type="picture-card"
                :file-list="ID_image"
                @preview="handlePreview"
                @change="handleChange"
                name="file">
            <div v-if="ID_image.length < count">
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
            </div>
        </a-upload>
        <a-modal v-model="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>

</template>

<script>
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    export default {
        name: "index",
        props: {
            ID_image: {
                type: Array,
                default: () => {
                    return []
                }
            },
            count: {
                type: Number,
                default: 1
            },
            isShow: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
                previewVisible: false,
                previewImage: '',
            }
        },
        methods: {
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleCancel() {
                this.previewVisible = false;
            },
            handleChange({ fileList }) {
                this.$emit('handleChange', fileList)
            }
        }
    }
</script>

<style scoped>

</style>
